type PostType = 'blogpost' | 'standard' | 'embed' | 'gallery';

export const getGAPostType = (typeId: number): PostType => {
  if (typeId === 1) {
    return 'blogpost';
  }
  if (typeId === 2) {
    return 'standard';
  }
  if (typeId === 3) {
    return 'embed';
  }
  return 'gallery';
};
